// 判断是在ios端还是在android端
function judgeType(){
    let userAgent = navigator.userAgent;
    if((/MicroMessenger/i.test(userAgent)) && (/ipad|iphone|ipod/i.test(userAgent))){
        return "isWxIos"
    }else if((/MicroMessenger/i.test(userAgent)) && (/Android/i.test(userAgent))){
        return "isWxAndroid"
    }else if (/ipad|iphone|ipod/i.test(userAgent)) {
       return "ios"
    }else if(/Android/i.test(userAgent)){
        return "android"
    }else{
        return ""
    }
}
import api from '@/api/index'
// 获取参数
function getQueryVariable(variable)
{
       var query = window.location.search.substring(1);
       var vars = query.split("&");
       for (var i=0;i<vars.length;i++) {
               var pair = vars[i].split("=");
               if(pair[0] == variable){return pair[1];}
       }
       return(false);
}

function getCookie(name) {
    let aa = document.cookie.split(';')
    let bb = null;
    aa.forEach(val => {
        if (val.indexOf(name) !== -1) {
            bb = val.split('=');
        }
    })
    localStorage.setItem(name, bb && bb[1])
    return bb && bb[1];
}

const getUserAgent = (getWechat = true) =>{
    const ua = window.navigator.userAgent.toLowerCase();
    if(getWechat && ua.indexOf('micromessenger') !== -1) {
        return 'wechat'
    } else if(getCookie('source-host') == 'app') {
        if(ua.indexOf('android') > -1 || ua.indexOf('adr') > -1) {
            return 'android'
        } else if(/(ios|ipad|iphone)/.test(ua) || ua.indexOf('macintosh') > -1 && 'ontouchend' in document) {
            return 'ios'
        }
        return 'h5'
    }
    return 'h5'
};
// 获取入驻机构域名
async function getSaasBranchDomain(branchId){
    let h5domain = ''
    await api.entityGoodsDetail.getSaasBranchDomain(branchId).then((res) => {
        if (res.success && res.data && res.data.length) {
            res.data.forEach((item) => {
                if(item.branchDomain){
                    if(item.branchDomain.includes('h5')){
                        h5domain = item.branchDomain
                    }
                }
            })
            if (h5domain) {
                let protocol = 'https://'
                // if (h5domain.includes('testing3')) {
                //     protocol = 'http://'
                // }
                h5domain = protocol + h5domain
            }
        }
    })
    return h5domain
}
const mobileDateFormat = (value) => (value ? value.replace(/-/g, '/') : '');
export default {
    judgeType,
    getQueryVariable,
    getUserAgent,
    getCookie,
    getSaasBranchDomain,
    mobileDateFormat,
}