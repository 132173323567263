const urlName = '/operate/v1'
export default API => ({
    getApp(params) {
        return API({
            url: `${urlName}/version/getNewestAppInfo` ,
            method:  'get',
            params,
        });
    },
})

