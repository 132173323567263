<template>
    <div>
        <van-nav-bar
            :fixed="true"
            :title="pageTitle"
            left-arrow
            @click-left="onClickLeft"
        />
        <van-nav-bar />
    </div>

</template>
<script>
export default {
  data() {
    return {
      pagePath: "",
      pageTitle: ""
    };
  },
  mounted() {
    this.pagePath = this.$route.path;
    if (this.pagePath == "/cooperationCases") {
      this.pageTitle = "合作案例";
    }
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    }
  }
};
</script>
<style scoped>
.van-nav-bar--fixed {
  position: fixed !important;
  z-index: 10;
}
</style>

