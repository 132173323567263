import sensors from 'sa-sdk-javascript'

let envArr = ['testing1', 'testing2', 'testing3', 'fat', 'uat'];
let env = window.location.port ? 'testing3' : 'prod';
envArr.forEach(d => {
  if (window.location.hostname.includes(d)) {env = d}
})
let project = 'default'
if (process.env.NODE_ENV === 'production' && env === 'prod') {
	project = 'production'
}

sensors.init({
    server_url: `https://sc-datasink.wdeduc.com/sa?project=${project}`,
    is_track_single_page: true,
    show_log: false,
    app_js_bridge:true,
    heatmap: {
        clickmap: 'default',
        scroll_notice_map: 'default'
    }
})

function getUserInfo (key) {
    const userData = localStorage.getItem('WDuserInfo')
    let userInfo = null
    if (userData) {
        userInfo = JSON.parse(userData)
        return userInfo[key]
    }
    return ''
}
sensors.registerPage({
    platform_type: 'H5',
    product_name: '学员端',
    user_type: function () { // 用户角色
        return '学员'
    },
    phone_number: function () {
        return getUserInfo('phone')
    },
    user_real_name: function () {
        return getUserInfo('name')
    },
    saas_id: function () {//所属租户ID
        const saasId = localStorage.getItem('saasId')
        return saasId
    },
    institution_id: function () { // 所属机构ID
        const branchId = localStorage.getItem('code') || localStorage.getItem('branch')
        branchId && sensors.identify(branchId, true)
        return branchId || null
    },
    institution_name: function () { // 所属机构名称
        const branchId = localStorage.getItem('branch')
        const branchList = JSON.parse(localStorage.getItem('branchList'))
        let branchName = ''
        if (branchId) {
            branchName = branchList.filter(item => {
                return item.value === branchId
            })[0].text || ''
        }
        return branchName
    },
    is_login: function () {
		// 是否登录
        const h5token = localStorage.getItem('token')
        return h5token ? 1 : 0
	},
})

sensors.quick('autoTrack') // 首次触发页面加载事件 $pageview
// sensors.quick('isReady',function(){
// 	console.log(sensors.quick('getAnonymousID'))
// })
export default sensors
// 自定义事件埋点
// this.$sensors.track('ViewSupplyChainDetails'[, param][,callback])
