<template>
  <div class="search-box">
    <input
      v-model="param.newsTitle"
      class="search-input"
      type="text"
      :placeholder="values"
      @keyup.13="getList(param.newsTitle)"
    />
    <img
      class="search-icon"
      src="../modules/account/asset/images/search.png"
    />
    <div class="icon-box" />
    <button class="search-btn" @click="getList(param.newsTitle)">搜索</button>
  </div>
</template>

<script>
export default {
  name: "Search",
  props: {
    values: {
      type: String,
      default: "请输入搜索内容",
    },
  },
  data() {
    return {
      param: {
        newsTitle: "",
        page: 1,
        rows: 10,
        status: 1,
        newsClassify: "8",
      },
      list: [],
      pagetotal: 0,
    };
  },
  methods: {
    getList(keyword) {
      this.$emit("getSearchList", keyword);
    },
  },
};
</script>
<style lang="less" scoped>
// @import url("../common/fonts/font.css");
.search-box {
  position: relative;
  width: 100%;
  height: 136px;
  padding: 30px 0;
  .search-input {
    position: relative;
    display: inline-block;
    width: 600px;
    height: 66px;
    padding: 16px;
    padding-left: 70px;
    margin-left: 30px;
    border: 2px solid #e6e6e6;
    border-radius: 66px;
    font-size: 24px;
    color: #383838;
    box-shadow: 0px 0px 0px 0px;
    background: #fff;
    -webkit-appearance: none;
    &::placeholder {
      color: #b2b2b2;
    }
  }
  .search-icon {
    position: absolute;
    width: 0.7rem;
    height: 0.7rem;
    top: 1.4rem;
    left: 1.4rem;
  }
  .icon-box {
    position: absolute;
    top: 54px;
    left: 54px;
    display: inline-block;
    font-family: "Ionicons";
    font-size: 26px;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    vertical-align: middle;
  }

  *,
  :after,
  :before {
    box-sizing: border-box;
  }
//   .icon-box::before {
//     content: "\F2A7";
//   }
  .search-btn {
    width: 96px;
    text-align: center;
    padding: 17px 24px;
    padding-right: 0;
    font-size: 24px;
    color: #f1443a;
    border: none;
    background: none;
  }
}
/deep/.ivu-btn-primary {
  width: 55px;
  font-size: 24px;
  background: none;
  margin-left: 14px;
  border: none;
  color: #000;
}
</style>
