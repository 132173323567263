/** 
 * 首页配置商品
 */
<template>
  <div class="home-comm-w">
    <!-- <p class="training-title">
      <span>商品</span>
      <span class="teainingMore">
        <label @click="toGoodsList">更多</label>
        <svg-icon icon-class="arrow-right" />
      </span>
    </p> -->
    <div class="searchGoodsList"    v-for="(item, idx) in  courseListArray" :key="idx"  @click="jumpGoodsDetailHandle(item)">
        <div class="goodsPoster">
            <img class="goodsPosterImg" :src="item.picUrl"/>
            <img v-if="item.taskType == 1" src="../../assets/images/courseGoods/bxk_hot.png" class="goodsHot"/>
            <img v-if="item.taskType == 2" src="../../assets/images/courseGoods/xxk_hot.png" class="goodsHot"/>
            <img v-if="item.taskType == 3" src="../../assets/images/courseGoods/gke_hot.png" class="goodsHot"/>
            <p class="goodsSignUpNum">{{item.purchaseNo}}人已报名</p>
        </div>

        <div class="goodsDetailInfor">
            <div class="freeInfor">
                <div class="freeInforLeft textCut">{{item.name}}</div>
                <div v-if="!item.hidePriceTag">   
                    <div class="freeInforRight"  v-if="item.isFree">免费</div>
                    <div class="freeInforRight" v-else-if="item.salePrice>=0 && item.basePrice">
                        <p class="salePrice">¥{{item.salePrice}}</p>
                        <p class="initPrice">¥{{item.basePrice}}</p>
                    </div>
                    <div class="freeInforRight" v-else-if="item.salePrice>=0 && !item.basePrice">
                        <p class="salePrice">¥{{item.salePrice}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
  export default {
    name: 'GridLayoutCourse',
    props: {
      // pageNum: {
      //   type: Number,
      //   default: 1
      // },
      // code: {
      //   type: String,
      //   default: ''
      // }
      data: {
        type: [Object, Array],
        default: () => {
          return {
            tempData: {
              specialData: {
                classTitle: '',
                articleClassID: '',
                code: ''
              }
            }
          }
        },
      },
    },
    data() {
      return {
        courseListArray: [],
      }
    },
    mounted() {
      this.getData()
    },
    methods: {
      toGoodsList() {
        this.$router.push({
          path: '/goodsList'
        })
      },
      async getData(type) {
            const params = {
                pageNum: 1,
                pageSize:  1,
            }
            const data = {
                categoryCodes: this.data.tempData.specialData.code,
                name: '',
                type: 3,
            }
            let res = await this.$api.courseGoods.findGoodsList(params, data)
            if (res.data && res.data.list) {
                this.courseListArray  = res.data.list;
            } else {
                this.$toast({
                    message: res.message,
                    duration: 4000,
                });
            }
        },
        jumpGoodsDetailHandle(item) {
          this.$router.push({
              path: '/goodsDetail',
              query: {
                  goodsId: item.id,
                  backName:this.$route.path,
                  goodsKind:item.goodsKind ,// 1 商品 2 分销商品
              }
          })
      },
    }
  }
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
.home-comm-w
  box-sizing: border-box
  padding: 60px 30px
.training-title {
  height: 44px;
  display: flex 
  justify-content: space-between
  align-items: center
  padding-bottom: 30px
  span {
    font-size: 40px;
    font-weight: 500;
    color: #000000;
    line-height: 40px;
  }
  .teainingMore {
    height: 44px;
    font-size: 26px;
    color: #666666;
    text-align: center;
    line-height: 44px;
    .more_right {
      position: relative;
      i {
        position: absolute;
        top: 0px;
        margin-top: 2px;
      }
    }
  }
}
.searchGoodsList {
    border-radius: 18px;
    margin-bottom:32px;
}

.goodsPoster {
    position:relative;

}

.goodsPosterImg {
    display:block;
    width:690px;
    height:516px;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;

}

.goodsHot {
    width:148px;
    height:64px;
    position:absolute;
    top:0;
    left:24px;


}

.goodsSignUpNum {
    position:absolute;
    right:24px;
    bottom:24px;
    padding:0px 24px;
    height: 44px;
    line-height: 44px;
    background:rgba(255, 255, 255, .7);
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #333333;
    border-radius: 24px;;
}

.goodsDetailInfor {
    background:#fff;
    padding:24px;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;

}

.freeInfor {
    display: flex;
    align-items: center;
    justify-content: center;
    height:96px;
}


.freeInforLeft{
    font-size: 32px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: #333333;
    line-height: 48px;
    flex: 1;
}

.freeInforRight{
    font-size: 36px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: #F1430D;
    line-height: 36px;
    width:auto;
    text-align:right;
    .salePrice {
        font-size: 36px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #FF4655;
        line-height: 36px;
    }
    .initPrice {
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: bold;
        color: #999999;
        line-height: 24px;
        margin-top:12px;
        position:relative;
        right:4px;
        text-decoration:line-through;
        margin-left:4px
    }
}
.textCut {
    overflow : hidden;
    display: -webkit-box;
    -webkit-line-clamp:2;
    -webkit-box-orient: vertical;
}

 
</style>
