export default API => ({
    findDoorCertificateUserBranchList() {
        return API({ 
            url: '/gx-homemaking/v1/task/findDoorCertificateUserBranchList',
            method:'get',
        });
    },
    getCertificate() {
        return API({ url: `/gx-homemaking/v1/homemakingInfo/queryHomemakingInfoCertificateH5`,method:'get'});
    },
    getCertificateByqrcode(id) {
        return API({ url: `/gx-homemaking/v1/homemakingInfo/queryHomemakingInfoCertificate?id=${id}`,method:'get'});
    },
    getTaskList(){
        return API({ 
            url: '/gx-homemaking/v1/task/taskList?type=1',
            method:'get'
        });
    },
    // 商品详情
    consumeOrderDetailInfo(code){
        return API({ 
            url: `/order/v1/orderDetailConsume/consumeOrderDetailInfo?code=${code}`,
            method:'get'
        });
    },
    // 商品领取
    consume(code){
        return API({ 
            url: `/order/v1/orderDetailConsume/consume?code=${code}`,
            method:'post'
        });
    }
})
