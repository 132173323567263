let RouterArr = [];
let RouterArr2 = [];

const Routers = require.context('./../modules/', true, /\.router\.js$/)
const wdnRouters = require.context('wdn-h5/src/modules', true, /\.router\.js$/);

Routers.keys().forEach(key => {
  RouterArr = RouterArr.concat(Routers(key).default)
})
wdnRouters.keys().forEach(key => {
  RouterArr2 = RouterArr2.concat(wdnRouters(key).default)
})
// RouterArr.sort((a, b) => { 
//   return a.meta.weights - b.meta.weights
// })
export default [
  ...RouterArr,
  ...RouterArr2, // 大平台的页面引入
]
