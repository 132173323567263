<template>
  <van-dialog v-model="show" class="course-dialog" :showConfirmButton="false" :showCancelButton="false">
    <div class="playboxscore">
      
      <div class="playboxscore-content">
        
        <div class="course-title">
          <img class="course-title-img" src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-h5/course/course-title.png"/>
          <img class="course-close" src="../assets/images/course/close.png" @click="clickclose"/>
        </div>
        <div class="course-tip">
          {{issubmit?'点击星星可以评价哦':'已点评该课程'}}
        </div>
        <div class="course-pfwjx2">
          <van-rate v-model="count" :size="32" color="#ffd21e" void-icon="star" :readonly="issubmit?false:true" void-color="#eee"/>
          <!-- <p v-for="numbercount in count" :key="numbercount">
            <img src="../assets/images/course/pfwjx2.png" @click="clickbright(numbercount)"/>
          </p>
          <p v-for="numbercount in 5-count" :key="numbercount+count" >
            <img src="../assets/images/course/pfwjx3.png" @click="clickbright(numbercount+count)"/>
          </p> -->
        </div>
        <div class="course-footer-content"  v-if="issubmit">
          <div :class="['course-footer',count>0?'courseactive':'']" @click="clickSubmit"><span>提交</span></div>
        </div>
        
      </div>
    </div>
  </van-dialog>

  
</template>
<script>

export default {
  name: "playboxscore",
  props: {
    myScore:{type:Number,default:0},
    issubmit:{type:Boolean,default:false},

  },

  data () {
    return{
      show:true,
      count:this.myScore
    }
  },
  methods:{
    clickbright(clickbright){
      if(this.issubmit){
        this.count=clickbright;
      }
    },
    getParent(parent,fn,item) {
        if (parent[fn]) {
            parent[fn](item)
        } else {
            this.getParent(parent.$parent, fn,item)
        }
    },
    clickclose(){
      this.getParent(this.$parent, 'clickclose');
    },
    clickSubmit(){
      if(this.count>0){
        this.getParent(this.$parent, 'evaluateCourse',this.count);
      }
      
      
    },
  },
};
</script>
<style lang="stylus">
  .course-dialog.van-dialog
    width 15.7333rem !important;
    background: none !important;
  .playboxscore
    width 590px
    &-content
      width 590px
      background #FFFFFF
      box-shadow 0px 0px 20px 0px rgba(0, 0, 0, 0.1)
      border-radius 24px
      margin 0 auto
      text-align center
      margin-top 188px
      
      .course-title
        height 180px
        position: relative;
        .course-title-img
          width 540px
          height 368px
          position: absolute;
          left: 22px;
          top: -188px;
        .course-close
          width 64px
          position: absolute;
          right: 24px;
          top: 14px;
      .course-tip
        font-size: 32px;
        font-weight: 400;
        color: #202124;
        line-height: 32px;
        margin-top 32px;
      .course-pfwjx2
        height 1.5rem
        padding 64px 0;
        p
          display inline-block
          img
            width 64px

        // padding-bottom 16px
        .van-rate__item:not(:last-child)
          padding-right: 16px;
          box-sizing border-box;
      .course-footer-content
        padding-bottom 48px
      .course-footer
        width 494px
        height 80px
        line-height 80px
        background linear-gradient(136deg, #407DF7 0%, #416DF6 100%)
        border-radius 44px
        opacity 0.3
        margin 16px auto 0
        &.courseactive
          opacity 1
        span
          opacity 1
          color #FFFFFF
          font-size: 32px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 44px;




</style>
