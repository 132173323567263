const urlName = '/gx-homemaking/v1'

export default API => ({
    jumpPaySaveParams(data) {
        return API({
            url:`/pay/wechat/cache/put`,
            method:'post',
            data
        });
    },
    findByUuid({ params, data }) { 
        return API({ url: `${urlName}/moneyRecord/findByUuid`, method:'get', params, data });
    },
    moneyRecordConfirm({ params, data }) { 
        return API({ url: `${urlName}/moneyRecord/confirm`, method:'post', params, data });
    },
    //获取用户信息
    getPayUserInfo({ params, data }) { 
        return API({ url: `${urlName}/moneyRecord/getPayUserInfo`, method:'get', params, data });
    },
    //获取appId
    getAppId(){
        return API({
            url: `${urlName}/wx/getAppIdAndSecret`,
            method: 'get',
        });
    },
    //获取openId
    getUserInfo(code){
        return API({
            url: `${urlName}/wx/getUserInfo?code=${code}`,
            method: 'get',
        });
    },
    //保存昵称
    saveWxName({ params, data }) { 
        return API({ url: `${urlName}/moneyRecord/saveWxName`, method:'post', params, data });
    },
    prePayOrder({data, params}) {
        return API({ url: '/gx-homemaking/v1/moneyRecord/prePayOrder',method:'post', params, data});
    },
})