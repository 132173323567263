var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wdc-app",attrs:{"id":"wdc-app"}},[(_vm.cmsPageShowBackNav.includes(_vm.$route.path))?_c('addCmsPageNav'):_vm._e(),_c('router-view'),(_vm.isDdy)?_c('ddyTabbar'):_c('tempalte',[(_vm.$route.name && _vm.showTabbar.includes(_vm.$route.name))?_c('van-tabbar',{staticClass:"tabbar-box",attrs:{"active-color":'#3760FF'},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('van-tabbar-item',{attrs:{"name":"home"},on:{"click":function($event){return _vm.gopage('home')}}},[_c('img',{staticClass:"nav-icon",attrs:{"src":_vm.$route.name == 'jz_homepage'
            ? require('./assets/images/home/home-2.png')
            : require('./assets/images/home/home-1.png')}}),_c('br'),_vm._v(" 首页 ")]),_c('van-tabbar-item',{attrs:{"name":"learning"},on:{"click":function($event){return _vm.gopage('learning')}}},[_c('img',{staticClass:"nav-icon",attrs:{"src":_vm.$route.name == 'learning'
            ? require('./assets/images/home/learing-2.png')
            : require('./assets/images/home/learing-1.png')}}),_c('br'),_vm._v(" 学习 ")]),_c('van-tabbar-item',{attrs:{"name":"saccount"},on:{"click":function($event){return _vm.gopage('saccount')}}},[_c('img',{staticClass:"nav-icon",attrs:{"src":_vm.$route.name == 'saccount'
            ? require('./assets/images/home/account-2.png')
            : require('./assets/images/home/account-1.png')}}),_c('br'),_vm._v(" 我的 ")])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }