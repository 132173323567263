const tipStyle = `
  display: block;
  background-color: #717688;
  color: #fff;
  padding: 20px 32px;
  line-height: 28px;
  position: fixed;
  left: 50%;
  bottom: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  border-radius: 200px;
  display: none;
  z-index: 999;
  font-size: 28px;
  text-align: center;
`

export function textTip(str, t, callBack) {
  t = t || 2000
  const dom = document.createElement("p")
  dom.setAttribute('class', 'text-tip')
  dom.setAttribute('style', tipStyle)
  document.body.appendChild(dom)
  const mytip = document.querySelector('.text-tip')
  
  mytip.style.display="block"
  mytip.innerHTML = str
  const tipHeight = mytip.offsetHeight
  
  //文字两行或两行以上
  if((tipHeight - 20) / 18 > 1){
    mytip.style.width = "55%"
  }
  setTimeout(function () {
      mytip.style.display="none"
      mytip.parentNode.removeChild(mytip)
      callBack && callBack()
  }, t)
}

export default {
  textTip
}
