// caller 调用方法名称（约定）  send_msg 传递信息
const commonBridgeFn = (caller, send_msg = "") => {
    return new Promise((resolve, reject) => {
        window.setupWebViewJavascriptBridge((bridge) => {
            bridge.callHandler(caller, send_msg, (data) => {
                let callBackData = data ? JSON.parse(data) : "";
                if (callBackData) {
                    resolve(callBackData);
                } else {
                    reject({
                        code: "",
                        msg: "未知回调",
                        content: "",
                    });
                }
            });
        });
    });
};

// 获取客户端容器版本
const useGetAppVersion = () => commonBridgeFn("getAppVersion");
// 刷新页面
const useReloadUrl = () => commonBridgeFn("reload");
// Toast
const useShowToast = ( send_msg ) => commonBridgeFn("showToast", send_msg);
// 新建页面 传递URL  进行跳转
const useNewPage = ( pageURL ) => commonBridgeFn("newPage", pageURL);
// NA页面返回
const useBack = () => commonBridgeFn("back");
// NA 分享
const useShare = (params) => commonBridgeFn("share",params);
// 进入 NA 视频详情页
const useGotoVideoDetail = () => commonBridgeFn("back");
// 获取手机位置信息
const useGetLocation = () => commonBridgeFn("getLocation");
//进入NA单个视频播放页
const gotoSingleVideo = (params) => commonBridgeFn("gotoSingleVideo",params);
//进入NA视频播放上下切换列表页
const gotoListVideo = (params) => commonBridgeFn("gotoListVideo",params);
// 跳转到NA页面
const jumpNAPage = (params) => commonBridgeFn("jumpNAPage",params);
// 获取城市
const getCurrentCity = (params) => commonBridgeFn("getCurrentCity",params);
// 选择城市
const selectCity = (params) => commonBridgeFn("selectCity",params);
// 拨打电话
const phone = (params) => commonBridgeFn("phone",params);

// 基础接口
export default {
    useGetAppVersion,
    useShowToast,
    useReloadUrl,
    useNewPage,
    useBack,
    useShare,
    useGotoVideoDetail,
    useGetLocation,
    gotoSingleVideo,
    gotoListVideo,
    jumpNAPage,
    getCurrentCity,
    selectCity,
    phone
};
