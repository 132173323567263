export default API => ({
    findIndexTrain() {
        return API({ //简介
            url: '/train/v1/train/findIndexTrain'
        });
    },
    getLatelyLive(params) {
        return API({
            url: 'live/v1/liveRoomUserX/getIndexLatelyLive',
            params
        })
    },
    // 获取模拟考试训练营
    findExamTrain({data, params}) {
        return API({
            // url: '/train/v1/train/findExamTrain'
            url: '/order/v1/open/goods/findGoodsGiftByGoodsId', params, data
        })
    },
    //旧版接口
    findSaleGoods() {
        return API({
            url: `/order/v1/open/goods/findSaleGoods`
        });
    },
    //新接口
    findGongxinSale() {
        return API({
            url: '/saas/v1/gongXin/homePage/findSaleGoods'
        })
    },
    // getGoodsById(id) {
    //     return API({
    //         url: `/order/v1/open/goods/findGoodsById?id=${id}`,
    //     });
    // },
    // checkUserCanByGoods(goodsId) {
    //     return API({
    //         url: `/order/v1/open/goods/checkUserCanByGoods?goodsId=${goodsId}`,
    //     });
    // },

    findWangHongSaleGoods() {
        return API({ 
            url: `/order/v1/open/goods/findWangHongSaleGoods`
        });
    },
    findGoodsGiftByGoodsId(goodsId) {
        return API({ 
            url: `/order/v1/open/goods/findGoodsGiftByGoodsId?goodsId=${goodsId}`
        });
    },
    findGoodsById({data, params}) {
        return API({ url: '/order/v1/open/goods/findGoodsById', params, data });
    },
    checkUserCanByGoods({data, params}) {
        return API({ url: '/order/v1/open/goods/checkUserCanByGoods', params, data });
    },
    // placeOrder({data, params}) {
    //     return API({ url: `/order/v1/open/order/placeOrder`, method:'post', params, data });
    // },
    payChannel(payChannel,{data, params}) {
        return API({ url: `/pay/v1/pay/preOrder?payChannel=${payChannel}`, method:'post', params, data });
    },
    findAndSetPayStatus(payNo) {
        return API({ url: `/pay/v1/pay/findAndSetPayStatus?payNo=${payNo}`, method:'post',});
    },
    cancelPayOrder(payNo){
        return API({ url: `/pay/v1/pay/cancelPayOrder?payNo=${payNo}`, method:'post'});
    },
    userExistOpenId(){
        return API({ url: `/pay/v1/oauth/userExistOpenId`, method:'get'});
    },
    findWebAccessToken(codes){
        return API({ url: `/pay/v1/oauth/findWebAccessToken?code=${codes}`, method:'get'});
    },

    //查看openid

    housekeepingTrain() {
        return API({ //简介
            url: '/train/v1/jz/findIndexTrain'
        });
    },
    // 网红商品详情
    findHongGoodsById({data, params}) {
        return API({ url: '/order/v1/open/goods/findWangHongSaleGoods', params, data });
    },
    prePayOrder({data, params}) {
        return API({ url: `/order/v1/open/order/prePayOrder`,method:'post', params, data});
    },
    //获取菜单配置
    getMenu(url) {
        return API({
            url: `/modular/admin/v1/navigation/getMenu?domain=${url}`,
            method: 'post'
        });
    },
    //立即报名下单
    order(data) {
        return API({
            url:`/order/v1/open/order/placeOrder`,
            method:'post',
            data
        });
    },
})
