let base = require('js-base64').Base64;
const hostname = window.location.hostname
const port = window.location.port;

let envArr = ['testing1', 'testing2', 'testing3', 'fat', 'uat'];
let env = window.location.port ? 'testing3' : '';
envArr.forEach(d => {
  if (window.location.hostname.includes(d)) {env = d}
})


let API = env ? `${window.location.protocol}//hrss-api.${env}.wdeduc.com` : `${window.location.protocol}//hrss-api.wdeduc.com`;


const yingjiURL = window.location.origin;

const host =  port ? 'jiazheng.saas-h5.testing3.wdeduc.com' : hostname;

const confUrl = base.encode(host);

export default {
    API,
    yingjiURL,
    host,
    confUrl,
};
