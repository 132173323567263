<template>
  <van-tabbar
    v-if="$route.name && showTabbar.includes($route.name)"
    class="tabbar-box"
    v-model="active"
    :active-color="'#0089FF'"
  >
    <van-tabbar-item
      name="home"
      @click="gopage('home')"
    >
      <img
        class="nav-icon"
        :src=" $route.name == 'mh_homepage' 
              ? require('./assets/images/mh_home_sel.png')
              : require('./assets/images/mh_home_no.png')"
      />
      <br />
      首页
    </van-tabbar-item>
    <van-tabbar-item
      name="opcourseGoods"
      @click="gopage('opcourseGoods')"
    >
      <img
        class="nav-icon"
        :src="
            $route.name == 'opcourseGoods'
              ? require('./assets/images/mh_goods_sel.png')
              : require('./assets/images/mh_goods_no.png')
          "
      />
      <br />
      技能提升
    </van-tabbar-item>
    <van-tabbar-item
      name="learning"
      @click="gopage('learning')"
    >
      <img
        class="nav-icon"
        :src="$route.name == 'learning'
              ? require('./assets/images/mh_learning_sel.png')
              : require('./assets/images/mh_learning_no.png')"
      />
      <br />
      学习
    </van-tabbar-item>
    <van-tabbar-item
      name="saccount"
      @click="gopage('saccount')"
    >
      <img
        class="nav-icon"
        :src="$route.name == 'saccount'
              ? require('./assets/images/mh_my_sel.png')
              : require('./assets/images/mh_my_no.png')"
      />
      <br />
      我的
    </van-tabbar-item>
  </van-tabbar>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      active: "home",
      NoTabBar: [
        "login",
        "verification",
        "trialClass",
        "applaypage",
        "verificationCode",
        "liveSkip",
        "promotionCenter",
        "promotionCenterMine",
        "cumulativeRevenueList",
        "cumulativeRevenueDetail",
        "seeTuiguangQrcode"
      ], // 页面不需要显示tabbar
      showTabbar: ["opcourseGoods", "learning", "saccount", "mh_homepage"] // 页面需要显示tabber
    };
  },
  computed: {
    // ...mapState(["tabbar"])
  },
  watch: {
    // $route() {
    //   if (this.showTabbar.includes(this.$route.name)) {
    //     this.tabbar.show = true;
    //   } else {
    //     this.tabbar.show = false;
    //   }
    // }
    $route(val) {
      if (val.name === "mh_homepage") {
        this.active = "home";
      } else if (val.name === "learning") {
        this.active = "learning";
      } else if (val.name === "saccount") {
       this.active = "saccount";
      } else if (val.name === "opcourseGoods") {
       this.active = "opcourseGoods";
      }

    }
  },
  methods: {
    gopage(name) {
      if (name === "home") {
        this.$router.push({
          name: "mh_homepage"
        });
      } else if (name === "learning") {
        this.$router.push({
          name: "learning"
        });
      } else if (name === "saccount") {
        this.$router.push({
          name: "saccount"
        });
      } else if (name === "opcourseGoods") {
        this.$router.push({
          name: "opcourseGoods"
        });
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
</style>