import Vue from 'vue';
import Router from 'vue-router';
import sensors from '../utils/sensors'
import api from './../api'
import routes from './routers';
import store from '../store/index';
import config from '../config/url';
import { Notify } from 'vant'

Vue.use(Router);
const router = new Router({
    mode: 'history',
    routes,
});


let params = {
    url: config.confUrl,
};
let frontendConfig = null

const SHOW_TABBARS = ['home', 'course', 'learning', 'saccount', 'gongxin_homepage', 'housekeeping_homepage', 'wanghong_homepage', 'goodsList', 'junmin_homepage'];

    router.beforeEach(async (to, from, next) => {
        console.log("-------------to.path-----------",to.path)
        if (to.name === 'idcard' || to.name === 'cepverification' || to.name === 'goodsList' || to.name === 'goodsDetail') {
            store.state.idcardBefore = from.fullPath
        }
        if (to.query.phone) {
            if (/^1(?:3[0-9]|4[5-9]|5[0-9]|6[12456]|7[0-8]|8[0-9]|9[0-9])[0-9]{8}$/.test(to.query.phone)) {
                const data = { phone: to.query.phone }
                api.usercenter.postAccountGetAuthCode({ data }).then(res => {
                    if (res.data) {
                        store.state.userInfo.phone = to.query.phone
                        sessionStorage.setItem("WdPhone", to.query.phone)
                        sessionStorage.setItem("realName", true)
                        if (to.name === 'login') {
                            next()
                        } else {
                            next({ name: 'login' })
                        }

                    }
                })
            } else {
                Notify({ type: 'warning', message: '手机号格式有误' })
                next({ name: 'login' })
            }
        }
        if (to.query.distributionId) store.state.distributionId = to.query.distributionId
        !frontendConfig && await api.usercenter.getConfiguration({ params }).then(res => {
            if (res.data) {
                frontendConfig = res.data.frontendConfig.filter(item => {
                    if (item.key === 'homepage') {
                        return true;
                    }
                })
                store.commit('frontendConfig', res.data.frontendConfig);
                store.commit('homepage', frontendConfig);
                sessionStorage.setItem('theme',res.data.theme)
                sessionStorage.setItem('frontendConfig',JSON.stringify(res.data.frontendConfig))
                localStorage.setItem("theme", res.data.theme || "");
                localStorage.setItem("frontendConfig",frontendConfig ? JSON.stringify(frontendConfig) : "");
                localStorage.setItem("configurationArr",JSON.stringify(res.data.frontendConfig));
                if (res.data.pageTitle) {
                    document.title = res.data.pageTitle;
                    localStorage.setItem('pageTitle', res.data.pageTitle)
                }
            }
        })
        const  token = localStorage.getItem('token')
        if(frontendConfig && token){
            if (localStorage.getItem('pageTitle')) {
                document.title = localStorage.getItem('pageTitle');
            }
        }
        console.log('===========to.meta.login',to.meta.login,token)
        if(!to.meta.login){
            if(to.path == '/') {
                setTimeout(()=> {
                    if (frontendConfig && frontendConfig.length && frontendConfig[0] && frontendConfig[0].value) {
                        console.log('route++++++',frontendConfig[0].value)
                        if(frontendConfig[0].value == 'home'){
                            next('/jz_homepage')
                        }else{
                            next(frontendConfig[0].value)
                        }
                    }else {
                        next('/login')
                    }
                },600)
            }else {
                console.log('===========111111111')
                next()
            }
        }else if (token && to.meta.login) {
            if(localStorage.getItem('branchResList')){
                let branchResList=JSON.parse(localStorage.getItem('branchResList'));
                let branch=localStorage.getItem('branch');
                let isShow=false;
                branchResList.forEach((val)=>{
                    if(val.branch==branch){
                        isShow=true;
                    }
                })
                if(!isShow){
                    localStorage.removeItem("code");
                    localStorage.removeItem("organ");
                    localStorage.removeItem("actor");
                    localStorage.removeItem("branchResList");
                    localStorage.removeItem("branch");
                    localStorage.removeItem("channel");
                    next('/login')
                }
            }
            // if (!localStorage.getItem('organ')) {
            //     await autoSelectOrganization();
            // }
            // let homepage;
            // try {
            //     homepage = frontendConfig[0].value;
                
            // } catch(e){
            //     console.log(e);
            // }
            // if(to.name == 'home'){
            //     next({
            //         path:'/jz_homepage'
            //     });
            // }else {
            //     next();
                
            // }
            if(to.path == '/') {
                setTimeout(()=> {
                    if (frontendConfig && frontendConfig.length && frontendConfig[0] && frontendConfig[0].value) {
                        console.log('route++++++',frontendConfig[0].value)
                        if(frontendConfig[0].value == 'home'){
                            next('/jz_homepage')
                        }else{
                            next(frontendConfig[0].value)
                        }
                       
                    }else {
                        next('/login')
                    }
                },600)
            }else {
                console.log('============ 路由跳转')
                next()
            }
            
        }else{
            next({ path: '/login' });
        }
        if (SHOW_TABBARS.includes(to.name)) {
            store.commit('setTabbar', {
                show: true,
                active: to.name
            });
        } else {
            store.commit('setTabbar', { show: false });
        }
    });

router.afterEach(() => {
    window.scrollTo(0, 0);
});

function autoSelectOrganization() {
    return api.account.getFindUserBranchList({}).then(res => {
        if (res.data && res.data.length) {
            !localStorage.getItem('code') && localStorage.setItem('code', res.data[0].code)
            !localStorage.getItem('organ') && localStorage.setItem('organ', res.data[0].organ)
            !localStorage.getItem('actor') && localStorage.setItem('actor', res.data[0].actor)
            !localStorage.getItem('branch') && localStorage.setItem('branch', res.data[0].branch)
            !localStorage.getItem('channel') && res.data[0].channel && localStorage.setItem('channel', res.data[0].channel)
            sensors.login(res.data[0].code)
        }
    }).catch( err => {
        console.log(err)
    })
}
export default router;
