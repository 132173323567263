export default API => ({
    getRegionList(params) {
      return API({url: '/gx-homemaking/v1/region/getRegionList',params});
    },
    
    // 判断是否是培训机构管理员
    isAdmin(){
      return API({ url: '/usercenter/v1/administrator/isAdmin'}); 
    },
    //门户登录
    postAccountLogin(data){
      return API({
        url: '/gx-homemaking/v1/platform/login',
        method: 'post',
        data
      });
    }
  })
  