var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$route.name && _vm.showTabbar.includes(_vm.$route.name))?_c('van-tabbar',{staticClass:"tabbar-box",attrs:{"active-color":'#0089FF'},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('van-tabbar-item',{attrs:{"name":"home"},on:{"click":function($event){return _vm.gopage('home')}}},[_c('img',{staticClass:"nav-icon",attrs:{"src":_vm.$route.name == 'mh_homepage' 
            ? require('./assets/images/mh_home_sel.png')
            : require('./assets/images/mh_home_no.png')}}),_c('br'),_vm._v(" 首页 ")]),_c('van-tabbar-item',{attrs:{"name":"opcourseGoods"},on:{"click":function($event){return _vm.gopage('opcourseGoods')}}},[_c('img',{staticClass:"nav-icon",attrs:{"src":_vm.$route.name == 'opcourseGoods'
            ? require('./assets/images/mh_goods_sel.png')
            : require('./assets/images/mh_goods_no.png')}}),_c('br'),_vm._v(" 技能提升 ")]),_c('van-tabbar-item',{attrs:{"name":"learning"},on:{"click":function($event){return _vm.gopage('learning')}}},[_c('img',{staticClass:"nav-icon",attrs:{"src":_vm.$route.name == 'learning'
            ? require('./assets/images/mh_learning_sel.png')
            : require('./assets/images/mh_learning_no.png')}}),_c('br'),_vm._v(" 学习 ")]),_c('van-tabbar-item',{attrs:{"name":"saccount"},on:{"click":function($event){return _vm.gopage('saccount')}}},[_c('img',{staticClass:"nav-icon",attrs:{"src":_vm.$route.name == 'saccount'
            ? require('./assets/images/mh_my_sel.png')
            : require('./assets/images/mh_my_no.png')}}),_c('br'),_vm._v(" 我的 ")])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }