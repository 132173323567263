const routerArr = [
  // {
  //   path: '/',
  //   name: 'jz_homepage',
  //   meta: { title:'' ,login:true},
  //   component: () => import(/* webpackChunkName: 'offlineTrain' */ './page/homepage.vue'),
  // },
  {
    path: '/jz_homepage',
    name: 'jz_homepage',
    meta: { title:'' ,login:true},
    component: () => import(/* webpackChunkName: 'offlineTrain' */ './page/homepage.vue'),
  },
  {
      path: '/homeCertificate',
      name: 'homeCertificate',
      meta: { title:'',login:true },
      component: () => import(/* webpackChunkName: 'offlineTrain' */ './page/homeCertificate.vue'),
  },
  {
      path: '/certificateDetail',
      name: 'certificateDetail',
      meta: { title:'回放' ,login:false},
      component: () => import(/* webpackChunkName: 'offlineTrain' */ './page/certificateDetail.vue'),
  },
  {
      path: '/buySharePage',
      name: 'buySharePage',
      meta: { title:'购买分享页' ,login:false},
      component: () => import(/* webpackChunkName: 'offlineTrain' */ './page/buySharePage.vue'),
  }
]
export default routerArr