<template>
    <div
        ref="myScroll"
        class="my-scroll"
        :class="[scrollState ? 'prohibit' : 'allow']"
        @scroll.passive="onScroll($event)"
        @touchmove="onScroll($event)"
    >
        <!-- top -->
        <div class="scroll-list">
            <slot name="scrollList" />
            <div class="scroll-bottom">
                <div v-if="state == 1">
                    <p>加载中</p>
                </div>
                <div v-if="state == 2">
                    加载完成
                </div>
                <div v-if="state == 3">
                    没有数据了
                </div>
                <div
                    v-if="state == '4'"
                    class="construction jiangzuo"
                >
                    <img
                        src="../modules/account/asset/images/专家讲座@2x.png"
                        alt=""
                    >
                    <p>更新中，敬请期待！</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script type="text/javascript">
export default {
    name: 'MyScroll',
    props: {
        onPull: {
            // 加载回调
            type: Function,
            require: true,
            default: () => {},
        },
        scrollState: {
            // 是否可滑动
            type: Boolean,
            require: true,
            default: false,
        },
        loaded: {
            type: Boolean,
            default() {
                return false;
            },
        },
    },
    data() {
        return {
            timeoutId: null,
            state: 0,
            myScroll: null,
        };
    },
    mounted() {
        this.myScroll = this.$refs.myScroll; // 获取滑条dom
    },
    methods: {
        /*
         * 加载中：1
         * 加载完成：2
         * 没有更多：3
         * 没有更多图文显示：4
         */
        setState(index) {
            // 修改状态
            this.state = index;
        },
        onScroll() {
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            if (
                !this.loaded
                && window.innerHeight + scrollTop - 50
                    >= this.myScroll.offsetHeight
            ) {
                clearTimeout(this.timeoutId);
                this.timeoutId = setTimeout(() => {
                    this.bottomCallback();
                }, 100);
            }
        },
        bottomCallback() {
            // 加载回调
            // console.log('回调', new Date().getTime())
            if (this.state !== 3) {
                this.state = 1;
                this.onPull();
            }
        },
    },
};
</script>
<style lang="less" scoped>
.allow {
    overflow: hidden;
    height: auto;
}
.prohibit {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    will-change: transform;
    transition: all 450ms;
    backface-visibility: hidden;
    perspective: 1000;
}
.my-scroll {
    position: relative;
    color: #999;
    padding-bottom: 15px;
    .scroll-top {
        text-align: center;
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
    }
    .scroll-list {
        overflow: hidden;
        min-height: 100%;
    }
    .scroll-bottom {
        // height: 2rem;
        text-align: center;
        line-height: 2rem;
        div {
            display: flex;
            height: auto;
            width: 100%;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            font-size: 24px;
            i {
                flex: 1 0 100%;
                display: block;
                height: 0.4rem;
            }
            img {
                width: 0.6rem;
            }
            p {
                flex: 1 0 100%;
            }
        }
        .construction {
            margin: 0 auto;
            text-align: center;
            margin-top: 60px;
            img {
                width: 260px;
            }
            &.jiangzuo img {
                width: 340px;
            }
            p {
                font-size: 32px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #383838;
                line-height: 45px;
            }
        }
    }
}
</style>
