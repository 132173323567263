export default {
    state: {
        config: {},
    },

    getters: {
        examListConfig(state) {
            return state.config.blocks.find(
                block => block.name === 'exam_list',
            );
        },

        examDetailConfig(state) {
            return state.config.blocks.find(
                block => block.name === 'exam_detail',
            );
        },
    },

    mutations: {
        setConfig(state, payload) {
            state.config = payload;
        },
    },

    actions: {
        setConfig({ commit, config }) {
            commit('setConfig', config);
        },
    },
};
