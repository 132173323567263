import Axios from 'axios';
import URL from '../config/url';
import {Notify} from 'vant'
// import { from } from 'core-js/fn/array';
// import CONF from '../config';

// const { STATUS_CODE } = CONF;
// const NO_MESSAGE = [STATUS_CODE.NO_LOGIN, STATUS_CODE.NO_SELECT_SHOP];
function getQueryString(name) {
    var search = window.location.search.substring(1)
    if (!search) {
        search = window.location.hash.split('?')[1]
    }
    if(search && search.indexOf('alipay') > -1 ) {
        return undefined
    }
    if (search && search.indexOf('fileUrl')===-1) {
        var obj = JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
        return name ? obj[name] : obj
    }
}
const baseURL = URL.API;
const service = Axios.create({withCredentials: true, baseURL})
// const token = localStorage.getItem('token') ? localStorage.getItem('token') : getQueryString('token')? getQueryString('token') : null
// 请求拦截器
service.interceptors.request.use(config => {
    const token = localStorage.getItem('token') ? localStorage.getItem('token') : getQueryString('token')? getQueryString('token') : null
    if (token) {
        config.headers['token'] = token || ''
    }
    if(getQueryString('token')!=undefined){
        localStorage.setItem("token",getQueryString('token'));
        localStorage.setItem("organ",getQueryString('organ'));
        localStorage.setItem("actor",getQueryString('actor'));
        localStorage.setItem("branch",getQueryString('branch'));
    }
    config.headers['organ'] = localStorage.getItem("organ") ? localStorage.getItem("organ") : getQueryString('organ')? getQueryString('organ'):''
    config.headers['actor'] = localStorage.getItem("actor") ? localStorage.getItem("actor") : getQueryString('actor')? getQueryString('actor'):''
    config.headers['branch'] = localStorage.getItem('branch') ? localStorage.getItem('branch') : getQueryString('branch')? getQueryString('branch'):''
    localStorage.getItem('channel') && (config.headers['channel'] = localStorage.getItem('channel'));
    return config
}, err => {
    console.error(err)
    // return Promise.reject(err)
})

// 响应拦截器
service.interceptors.response.use(config => {
    return config
}, err => {
    console.error(err)
    // return Promise.reject(err)
})

let stackArr = [];
let isPoint = false;
function loop () {
    setTimeout(() => {
        Notify({
            message: stackArr[0],
            type: 'danger',
            duration: 2000,
            onClose: () => {
                stackArr.shift();
                if (stackArr.length) {
                    loop();
                } else {
                    isPoint = false;
                }
            }
        })
        
    }, 2500)
}
/* eslint-disable */
function API(config) {
    const {
        url = '',
        data = null,
        params = null,
        headers = {},
        method = 'get',
        timeout = 30000
    } = config || {};
    return new Promise((resolve, reject) => {
        service({
            method, // 请求方式
            url, // 请求路径
            timeout, // 请求超时
            headers: {
                // "content-type": "application/json",
                ...headers
            }, // header体
            data, // body参数
            params //  URL参数
        }).then(res => {
            if (res.data.code) {
                // Notify({ type:'danger', message:res.data.message, duration:2000 })
                if (!stackArr.includes(res.data.message)) {
                    stackArr.push(res.data.message);
                }
               
                if (!isPoint) {
                    isPoint = true;
                    loop();
                }
                if (+res.data.code === 303 || +res.data.code === 10000) {
                    if(res.data.code===303){
                        localStorage.removeItem("token")
                    }
                    if(window.location.pathname === '/login'){
                        // return Promise.reject(res.data.message)
                        console.error(res.data.message)
                        return 
                    } 
                    // sessionStorage.clear()
                    let pathname=null;
                    if(window.location.pathname === '/goodsDetail') { // 商品详情页需要携带参数
                        // 
                        pathname=`?name=goodsDetail&`+window.location.search.substring(1)
                        window.location = `//${window.location.hostname}${window.location.port? `:${window.location.port}` : ''}/login${pathname}`
                        return 
                    }
                    if(window.location.pathname === '/buySharePage') { // 商品领取需要携带参数
                        // 
                        pathname=`?name=buySharePage&`+window.location.search.substring(1)
                        window.location = `//${window.location.hostname}${window.location.port? `:${window.location.port}` : ''}/login${pathname}`
                        return 
                    }
                    if(window.location.pathname){
                        pathname='?name='+((window.location.pathname).substr(1))
                    }
                    window.location = `//${window.location.hostname}${window.location.port? `:${window.location.port}` : ''}/login${pathname}`
                    // resolve('跳转登录页')
                }   
            }
            resolve({
                ...res.data,
                headers: res.headers
            })
            
        }).catch(err => {
            // reject(err)
            if(err && err.message){
                // console.log(err.message);
                let message = '网络异常，请检查网络';
                if(err.message === 'Network Error'){
                    message = '网络异常，请检查网络';
                }
                if (err.message.indexOf('timeout') !== -1) {
                    message = '网络超时';
                }
                if (!stackArr.includes(message)) {
                    stackArr.push(message);
                }
               
                if (!isPoint) {
                    isPoint = true;
                    loop();
                }
            }
            return err
        })
    })
}

export default API
