const routerArr = [
    {
        path: '/openPlatform/courseGoods',
        name: 'opcourseGoods',
        meta: { title:'开放平台商品列表' , login:false },
        component: () => import(/* webpackChunkName: 'openPlatform' */ './page/courseGoodsList.vue'),
    },
    {
        path: '/openPlatform/courseGoodsDetail',
        name: 'opcourseGoodsDetail',
        meta: { title:'开放平台课程详情',login:false },
        component: () => import(/* webpackChunkName: 'openPlatform' */ './page/courseGoodsDetail.vue'),
    },
  ]
  export default routerArr