<template>
  <div
    id="wdc-app"
    class="wdc-app"
  >
    <addCmsPageNav v-if="cmsPageShowBackNav.includes($route.path)" />
    <router-view />
    <ddyTabbar v-if="isDdy" />
    <tempalte v-else>
      <van-tabbar
        v-if="$route.name && showTabbar.includes($route.name)"
        class="tabbar-box"
        v-model="active"
        :active-color="'#3760FF'"
      >
        <van-tabbar-item
          name="home"
          @click="gopage('home')"
        >
          <img
            class="nav-icon"
            :src=" $route.name == 'jz_homepage'
              ? require('./assets/images/home/home-2.png')
              : require('./assets/images/home/home-1.png')"
          />
          <br />
          首页
        </van-tabbar-item>
        <!-- <van-tabbar-item
          name="goodsList"
          @click="gopage('goodsList')"
        >
          <img
            class="nav-icon"
            :src="
            $route.name == 'goodsList'
              ? require('./assets/images/home/goods_active.png')
              : require('./assets/images/home/goods_noAcitve.png')
          "
          />
          <br />
          课程
        </van-tabbar-item> -->
        <van-tabbar-item
          name="learning"
          @click="gopage('learning')"
        >
          <img
            class="nav-icon"
            :src="$route.name == 'learning'
              ? require('./assets/images/home/learing-2.png')
              : require('./assets/images/home/learing-1.png')"
          />
          <br />
          学习
        </van-tabbar-item>
        <van-tabbar-item
          name="saccount"
          @click="gopage('saccount')"
        >
          <img
            class="nav-icon"
            :src="$route.name == 'saccount'
              ? require('./assets/images/home/account-2.png')
              : require('./assets/images/home/account-1.png')"
          />
          <br />
          我的
        </van-tabbar-item>
      </van-tabbar>
    </tempalte>

  </div>
</template>

<script>
import { mapState } from "vuex";
import store from "./store/index";
import URL from "./config/url";
import ddyTabbar from "./view/ddyTabbar/ddyTabbar.vue";
import addCmsPageNav from "./view/common/addCmsPageNav.vue";
export default {
  data() {
    return {
      bannerItems: [],
      getUserInfo: true,
      active: "home",
      frontendConfig: null,
      NoTabBar: [
        "login",
        "verification",
        "trialClass",
        "applaypage",
        "verificationCode",
        "liveSkip",
        "promotionCenter",
        "promotionCenterMine",
        "cumulativeRevenueList",
        "cumulativeRevenueDetail",
        "seeTuiguangQrcode"
      ], // 页面不需要显示tabbar
      showTabbar: ["goodsList", "learning", "saccount", "jz_homepage"], // 页面需要显示tabber
      cmsPageShowBackNav:["/cooperationCases"],//配置的cms页面，添加自己的返回按钮
      theme: "",
      isDdy: false
    };
  },
  computed: {
    // ...mapState(["tabbar"])
  },
  watch: {
    $route(val) {
      if (val.name === "jz_homepage") {
        this.active = "home";
      } else if (val.name === "learning") {
        this.active = "learning";
      } else if (val.name === "saccount") {
       this.active = "saccount";
      } else if (val.name === "goodsList") {
       this.active = "goodsList";
      }
      this.renderPage();
    }
  },
  components: {
    ddyTabbar,
    addCmsPageNav
  },
  created() {
    let url = URL.confUrl;
    this.configuration(url);
    this.renderPage();
    // this.configMenuInfor()
    if (window.location.href.indexOf("dongdongjz") != -1) {
      this.isDdy = true;
    }
  },
  mounted() {
    setTimeout(()=>{
      if(this.$route.path=='/goodsDetail' && this.$route.query.goodsId){
        this.getDistributionGoodsInfo();
      }
    },500)
    
    document.addEventListener("visibilitychange", () => {
      var isHidden = document.hidden;
      if (!isHidden) {
        let localStorageId =
          localStorage.getItem("WDuserInfo") &&
          JSON.parse(localStorage.getItem("WDuserInfo")).accountId;
        let vuexId = this.$store.state.userInfo.accountId;
        console.log(localStorageId, vuexId);
        if (localStorageId && vuexId && localStorageId !== vuexId) {
          //重新加载页面，保持账号同步
          // this.$router.replace('/')
          this.$router.go(0);
        }
      }
    });
  },
  methods: {
    getDistributionGoodsInfo(){
        const params = {
            id:this.$route.query.goodsId
        }
        return this.$api.courseGoodsDetail.getDistributionGoodsInfo({params}).then(res => {
            if(res.data){
              document.title=res.data.branchName
            }
        })
    },
    configuration(url) {
      let params = { url };
      this.$api.usercenter.getConfiguration({ params }).then(res => {
        if (res.data) {
          this.theme = res.data.theme;
        }
      });
    },
    gopage(name) {
      if (localStorage.getItem("token")) {
        if (name === "home") {
          this.$router.push({
            name: "jz_homepage"
          });
        } else if (name === "learning") {
          this.$router.push({
            name: "learning"
          });
        } else if (name === "saccount") {
          this.$router.push({
            name: "saccount"
          });
        } else if (name === "goodsList") {
          this.$router.push({
            name: "goodsList"
          });
        }
      } else {
        if (name === "goodsList") {
          this.$router.push({
            name: "goodsList"
          });
        } else {
          this.$router.push({
            path: "/login",
            query: {
              name: "jz_homepage"
            }
          });
        }
      }
    },
    renderPage() {
      const { meta, params } = this.$route;
      this.config = store.getters.getConfigByPage(meta.moduleId, meta.name);
      this.routerParams = params;
    },
    async getAccountUserInfo() {
      const headers = {
        token: localStorage.getItem("token")
      };
      return this.$api.usercenter.getAccountUserInfo({ headers }).then(res => {
        if (res.data) {
          this.$store.state.userInfo = {
            ...this.$store.state.userInfo,
            ...res.data
          };
          localStorage.setItem(
            "WDuserInfo",
            JSON.stringify(this.$store.state.userInfo)
          );
        }
      });
    }
  }
};
</script>

<style lang="stylus">
html, body, .wdc-app {
  height: 100%;
}

.van-notify--danger {
  background-color: #ee0a24 !important;
}

.van-tabbar--fixed {
  padding-bottom: 0px;
  position: fixed !important;
  bottom: 0;
  left: 0;
}

.van-tabbar-item__text {
  text-align: center;
}

.nav-icon {
  width: 44px;
  margin-bottom:4px;
}

input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset;
  -webkit-text-fill-color: #333;
}

input[type=text]:focus, input[type=password]:focus, textarea:focus {
  -webkit-box-shadow: 0 0 0 1000px white inset;
}
</style>
