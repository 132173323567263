const urlName = '/usercenter/v1/account'

export default API => ({
    /**
     * 获取登录方式
     * @param { } 
     * 响应数据data：  
     */
    findLoginType (  data ) {
        return API({ url: `/usercenter/v1/branchProfile/findLoginType`, method: 'post' , data });
    },
    /**
     * 账号登录
     * @param { account: 账号, password: 密码 }  param2
     * 响应数据data：  用户token，用于标识用户的登录状态信息。
     */
    postAccountLogin({ params, data }) { 
        return API({ url: `${urlName}/login`, method:'post', params, data });
    },
    /**
     * 验证码登录
     * @param { account: 账号, authCode: 密码 } param2
     * 响应数据data：  用户token，用于标识用户的登录状态信息。
     */
    postAccountLoginByAuthCode({ params, data }) {
        return API({ url: `${urlName}/loginByAuthCode`, method:'post', params, data });
    },
    /**
     * 验证身份
     * @param {} 
     * 响应数据data：  
     */
    checkAuthCode (  params ) {
        return API({ url: `${urlName}/checkAuthCode`, method: 'post' , params });
    },
    /**
     * 忘记密码
     * @param { authCode password phone} 
     * 响应数据data：  
     */
    resetPassword ({  data }) {
        return API({ url: `${urlName}/resetPasswordNew`, method: 'post' , data });
    },
    /**
     * 注销登录
     * @param { token: 用户token } param1
     * 响应数据data：  boolean   注销登录的结构，true 成功；false 失败
     */
    postAccounLogout({ params, data }) {
        return API({ url: `${urlName}/logout`, method:'post', params, data });
    },
    /**
     * 获取验证码
     * @param { phone: 手机号 } param1
     * 响应数据data：  boolean   是否发送验证码成功，true 成功；false
     */
    postAccountGetAuthCode({ params, data }) {
        return API({ url: `${urlName}/getAuthCode`, method:'post', params, data });
    },
    
    getAuthCodeCommon({ params, data }) {
        return API({ url: `${urlName}/getAuthCodeCommon`, method:'post', params, data });
    },
    
  
    postRegisterResult ({ params, data }) {
        return API({ url: `${urlName}/getRegisterResult`, method: 'post', params, data });
    },
    /**
     * 修改密码
     * @param { password: 原密码, passwordNew: 新密码 } param2
     * 响应数据data：  boolean   修改密码的结果，true 成功；false 失败
     */
    postAccountChangePassword({ params, data }) {
        return API({ url: `${urlName}/changePassword`, method:'post', params, data });
    },
    /**
     * 重置密码
     * @param { password: 新密码, authCode: 验证码 } param1
     * 响应数据data：  boolean   是否发送验证码成功，true 成功；false
     */
    postAccountResetPassword({ params, data }) {
        return API({ url: `${urlName}/resetPassword`, method:'post', params, data });
    },
    /**
     * 注册
     * @param { password: 密码, account: 账号, authCode: 验证码 } param1
     * 响应数据data：  boolean   修改密码的结果，true 成功；false 失败
     */
    postAccountRegister({ params, data }) {
        return API({ url: `${urlName}/register`, method:'post', params, data });
    },
    /**
     * 获取登录用户信息
     * @param { password: 密码, account: 账号, authCode: 验证码 } param1
     * 响应数据data：  boolean   修改密码的结果，true 成功；false 失败
     */
    getAccountUserInfo({ params, data, headers }) {
        return API({ url: `${urlName}/getUserInfo`, params, data, headers });
    },
    /**
     * 
     * 获取配置项
     */
    getConfiguration({ params, data }) {
        return API({ url: `/modular/admin/v1/configuration/${params.url}`, data });
    },

    getFindDomainIfBindSaas() {
        return API({ // 详情列表
            url: `${urlName}/findDomainIfBindSaas`,
        });
    },
    // 个人测评-获取验证码
    getAuthCodeMiaoShen({ params, data }) {
        return API({ url: '/usercenter/v1/miaoshenaccount/getAuthCodeMiaoShen', method:'post', params, data });
    },
    //个人测评-登录
    loginByAuthCodeMiaoShen({ params, data }) {
        return API({ url: '/usercenter/v1/miaoshenaccount/loginByAuthCodeMiaoShen', method:'post', params, data });
    },
      /**
     * 查询个人资料
     * 响应数据data：{}
     */
    findUser () {
        return API({ url: `/usercenter/v1/user/findUser` });
    },

    // 获取已绑定手机
    getCurrentUserAccount () {
        return API({ url: `${urlName}/getCurrentUserAccount` });
    },
    // 获取已绑定邮箱
    getCurrentUserMail () {
        return API({ url: `${urlName}/getCurrentUserMail` });
    },
    // 根据原密码修改密码
    changePassword (  params ) {
        return API({ url: `${urlName}/changePassword`, method: 'post' , params });
    },
    // 换绑手机号、邮箱
    unBindUserAccount (data) {
        return API({ url: `${urlName}/unBindUserAccount`, method: 'post', data });
    },
    // 绑定手机号、邮箱
    bindUserAccount (data) {
        return API({ url: `${urlName}/bindUserAccount`, method: 'post', data });
    },
    // 换绑账号发送验证码
    getAuthCodePublic ({ params, data }) {
        return API({ url: `${urlName}/getAuthCodePublic`, method: 'post', params, data });
    },
    // 注册时获取民政租户的机构列表
    findMinZhengBranchList () {
        return API({
        url: '/usercenter/v1/branch/findMinZhengBranchList',
        });
    },
    // 获取城市列表
    getTree () {
        return API({ url: `/usercenter/v1/region/getTree` });
    },
    // 获取民政用户个人资料
    findMinzhengPersonal () {
        return API({ url: '/usercenter/v1/userMinZhengProfile/findSelfDatum' });
    },
    // 根据区域码获取养老机构列表
    findOldAgeInstitutionList( params ){
        return API({ url: '/usercenter/v1/userMinZhengProfile/findOldAgeInstitutionList', params }); 
    },
    // 保存民政个人资料
    saveMinzhengPersonal( data ){
        return API({ url: '/usercenter/v1/userMinZhengProfile/save', method:'post', data }); 
    },

    // 获取用户登录角色
    checkStuLoginRole( data ){
        return API({ url: `/usercenter/v1/role/checkStuLoginRole?source=${data.source}`, method:'get', data }); 
    },
    //获取养老地区数据
    findSaasIdList(){
      return API({ url: `/saas/v1/oldAgeInstitution/getRegionsInBranch`}); 
    },
})