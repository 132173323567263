import API from './../libs/axios'
// import API2 from './../libs/axios2'

let apiArr = {}
const Routers = require.context('./modules/', true, /\.js$/)
const wdnRouters = require.context('wdn-h5/src/api/modules', true, /\.js$/)


wdnRouters.keys().forEach(key => { 
  let urlArr = key.split("/");
  let apiKey=urlArr[urlArr.length-1].split(".")[0]
  apiArr[apiKey] = {
    ...apiArr,
    ...wdnRouters(key).default(API)
  }
})

Routers.keys().forEach(key => {
  let urlArr = key.split("/");
  let apiKey=urlArr[urlArr.length-1].split(".")[0]
  apiArr[apiKey] = {
    ...apiArr,
    ...Routers(key).default(API)
  }
  // 标准分类的接口需要其他域名的接口，前端处理，配合使用nginx.
  // if (apiKey === 'standard') {
  //   apiArr[apiKey] = {
  //     ...apiArr,
  //     ...Routers(key).default(API2),
  //   }
  // } 

})

export default {
  ...apiArr
}
