import Vue from 'vue';
Vue.use(Yox.plugin)
import Vant from 'vant';
import { Toast } from 'vant';
import {Dialog, Notify} from 'vant'
import 'babel-polyfill';
import 'vant/lib/index.css';
import Videojs from 'video.js';
import 'video.js/dist/video-js.css'
import App from './app.vue';
import router from './router';
import store from './store';
import config from './config';
import api from './api';
import Templates from './libs/template';
import Tools from '@stroll/tools';
import { textTip } from './utils/tip'
import sensors from './utils/sensors'
import './libs/rem';
import * as echarts from "echarts";
import 'wdn-h5/src/libs/saas.js'
import './styl/app.styl';
import NoSleep from "nosleep.js/dist/NoSleep.min.js";
import publicFn from './utils/publicFn';

import VideoPlayer from 'vue-video-player'
import SDK from '@wd/lowcode-h5'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)
import question from '@wd/question';
import '@wd/question/lib/question.css';

// 平台需要
import $WDJSBridge from '../src/libs/jsBridge/getBridgeFn'
import jsBridge from '../src/libs/jsBridge/jsBridge'
Vue.prototype.$WDJSBridge = $WDJSBridge
Vue.prototype.$publicFn = publicFn
jsBridge.initJSBridge()
// 保存登录信息
publicFn.getCookie()
Vue.config.ignoredElements.push(
    'wx-open-launch-app',
);

//引入
//解决和vue-video-player的暂停按钮的冲突问题，修改needsClick方法
import FastClick from './libs/fastclick'


//FastClick的ios点击穿透解决方案
FastClick.prototype.focus = function (targetElement) {
    let length;
    if (targetElement.setSelectionRange && targetElement.type.indexOf('date') !== 0 && targetElement.type !== 'time' && targetElement.type !== 'month') {
        length = targetElement.value.length;
        targetElement.focus();
        targetElement.setSelectionRange(length, length);
    } else {
        targetElement.focus();
    }
};
//初始化FastClick实例。在页面的DOM文档加载完成后
FastClick.attach(document.body)


Vue.use(question)

Vue.prototype.$sensors = sensors
Vue.prototype.$video = Videojs;
Vue.prototype.$NoSleep = NoSleep;
Vue.prototype.$textTip = textTip
Vue.prototype.$publicFn = publicFn;

// const ua = navigator.userAgent
// if (ua.match(/Android/i) !== null || ua.match(/iPad|iPod|iPhone/i) !== null) {
//    var src = '//cdn.jsdelivr.net/npm/eruda';
//    document.write('<scr' + 'ipt src="' + src + '"></scr' + 'ipt>');
//    document.write('<scr' + 'ipt>eruda.init();</scr' + 'ipt>');
// }


config.productionTip = process.env.NODE_ENV === 'development';

/**
 * @description 全局注册应用配置
 */
Vue.use(Vant);
Vue.use(Toast);
Vue.use(Dialog)
Vue.use(Notify)
Vue.use(Templates)

Vue.prototype.$echarts = echarts;
Vue.prototype.$config = config;
Vue.prototype.$tools = Tools
Vue.prototype.$api = api;
Vue.prototype.$eventBus = new Vue();
Vue.prototype.$sc = sensors


// generated buy dragging
function bindDynamicPage(pageList) {
    store.commit('storeSetPageList', pageList);
    pageList.forEach(p => {
        p.uri && router.addRoute({
            path: p.uri,
            name: p.pageName,
            meta: {
                noLogin: true,
            },
            component: () => import(/* webpackChunkName: 'error' */ './modules/dynamic/page/render.vue'),
        })
        p.manual_url && router.addRoute({
            path: p.manual_url,
            name: p.pageName,
            meta: {
                noLogin: true,
            },
            component: () => import(/* webpackChunkName: 'error' */ './modules/dynamic/page/render.vue'),
        })
    });
}

// generated buy layout
function getLayoutPage() {
    return api.configuration.getPageJson().then((data) => {
        if (data.data && data.data.clickList) {
            bindDynamicPage(data.data.clickList);
        }
    })
}

//将自定义页面加入到路由中 路由必须是历史模式
Promise.all([SDK.setLayoutToRouter(router,store)]).then(() => {
    new Vue({
     router,
     store,
     render: h => h(App),
   }).$mount('#wdc-app')
   }).catch(res=>{
    console.log(res)
    new Vue({
        router,
        store,
        // i18n,
        render: h => h(App),
    }).$mount('#wdc-app');
});
// import * as Sentry from "@sentry/browser";
// import { Vue as VueIntegration } from "@sentry/integrations";
// import { Integrations } from "@sentry/tracing";
// const hostname = window.location.hostname
// if (!hostname.includes('localhost')) {
//     Sentry.init({
//         dsn: "https://0f397e5914694fe9bb5ce3cd45913d43@sentry.corp.wdeduc.com/6",
//         integrations: [
//             new VueIntegration({
//                 Vue,
//                 tracing: true,
//             }),
//             new Integrations.BrowserTracing(),

//         ],
//         environment: process.env.VUE_APP_NODE_ENV,
//         release: process.env.VUE_APP_RELEASE,
//         logErrors: true,

//         // We recommend adjusting this value in production, or using tracesSampler
//         // for finer control
//         tracesSampleRate: 1.0,
//     });
// }


