const urlName = '/exam/v1'
export default API => ({
    //整体提交试卷答案
    commitAllPaper(data, params) {
        return API({
            url: `${urlName}/userPractice/commitAllPaper` ,
            method:  'post',
            params,
            data
        });
    },

    //单次提交试卷答案
    commitPaper(data, params) {
        return API({
            url: `${urlName}/userPractice/commitPaper`,           
            method: 'post',
            params,
            data
        });
    },

    //考试中-试题信息
    findQuestForExam(params) {
        return API({
            url: `${urlName}/userPractice/findQuestForExam`,
            mathod: 'get',
            params,
        })
    },

    //单次考试结果-详情
    findUserPaperInfo(params) {
        return API({
            url: `${urlName}/userPractice/findUserPaperInfo`,
            mathod: 'get',
            params,
        })
    },

    //试题标记
    markQuestion(params) {
        return API({
            url: `${urlName}/userPracticeQuestionX/question/mark`,
            mathod: 'get',
            params,
        })
    }
})

